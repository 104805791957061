const digitalEdge = 'https://p-gsg.digitaledge.cigna.com';
const staticPath = '/static/aep-pdp-control-sites-cigna-com';
const i18nLoadPath = (lngs, _namespaces) => {
    if (lngs === 'en-us') {
        return `${staticPath}/json/{{- ns}}.json`;
    }
    return `${staticPath}/{{lng}}/json/{{- ns}}.json`;
};
export const config = {
    logLevel: 'error',
    i18n: {
        backend: { loadPath: i18nLoadPath },
        detection: {
            order: ['htmlTag'],
        },
    },
    paths: {
        json: `${staticPath}/json/`,
    },
    endpoints: {
        igse: 'https://plans.cigna.com',
    },
    flags: {},
    api: {
        gateways: {
            campaigns: `${digitalEdge}/webservices/common/v1/campaigns`,
            medicarePlanFinder: `${digitalEdge}/igse/connecturedrx2`,
        },
    },
};
