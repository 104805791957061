import { initializeComponentLazyLoader } from '@cigna/cigna_csg_gsg_cipublic-bundle/lib/lazy.js';
// Lazy load with intersection observer
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appImportMap = [
    ['ciapp-medical-banner', () => import('@cigna/cigna_csg_gsg_ciapp-medical-banner/ciapp-medical-banner.js')],
    ['ciapp-medicare-plan-finder', () => import('@cigna/cigna_csg_gsg_ciapp-medicare-plan-finder/ciapp-medicare-plan-finder.js')],
];
// This component loading logic is necessary to load components in TeamSite eStudio (preview/edit modes) correctly
export const loadComponents = () => {
    let componentLoaderInitialized = false;
    document.addEventListener('DOMContentLoaded', () => {
        initializeComponentLazyLoader(appImportMap);
        componentLoaderInitialized = true;
    });
    if (!componentLoaderInitialized) {
        initializeComponentLazyLoader(appImportMap);
    }
};
